import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as a from "./index.module.scss";
import {PrismicRichText} from "@prismicio/react";

const PrivacyPolicySection = ({data}) => {

    return (
        <section className={`privacy_policy_section ${a.p_policy_section}`}>
            <Container>
                <Row>
                    <Col xxxl={2} xxl={2} xl={2} lg={2} md={12} sm={12} xs={12} xxs={12}></Col>
                    <Col xxxl={8} xxl={8} xl={8} lg={8} md={12} sm={12} xs={12} xxs={12}>
                        {/*Main Title*/}
                        <div className={a.title}>
                            <PrismicRichText field={data.main_title}/>
                        </div>

                        {/*Main Description*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.main_description}/>
                        </div>

                        {/*Information Collection Title*/}
                        <div className={a.sub_title}>
                            <PrismicRichText field={data.information_collection_title}/>
                        </div>

                        {/*Information Collection Description*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.information_collection_description}/>
                        </div>

                        {/*Usage of Information Title*/}
                        <div className={a.sub_title}>
                            <PrismicRichText field={data.usage_information_title}/>
                        </div>

                        {/*Usage of Information Description 1*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.usage_information_description_1}/>
                        </div>

                        {/*Usage of Information Description 2*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.usage_information_description_2}/>
                        </div>

                        {/*Usage of Information Description 3*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.usage_information_description_3}/>
                        </div>

                        {/*Log Data Title*/}
                        <div className={a.sub_title}>
                            <PrismicRichText field={data.log_data_title}/>
                        </div>

                        {/*Log Data Description 1*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.log_data_description_1}/>
                        </div>

                        {/*Log Data Description 2*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.log_data_description_2}/>
                        </div>

                        {/*Cookies Title*/}
                        <div className={a.sub_title}>
                            <PrismicRichText field={data.cookies_title}/>
                        </div>

                        {/*Cookies Description 1*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.cookies_description_1}/>
                        </div>

                        {/*Cookies Description 2*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.cookies_description_2}/>
                        </div>

                        {/*Security Description 2*/}
                        <div className={a.sub_title}>
                            <PrismicRichText field={data.security_title}/>
                        </div>

                        {/*Security Description 2*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.security_description_1}/>
                        </div>

                        {/*Security Description 2*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.security_description_2}/>
                        </div>

                        {/*Changes To This Privacy Policy Title*/}
                        <div className={a.sub_title}>
                            <PrismicRichText field={data.changes_to_this_privacy_policy_title}/>
                        </div>

                        {/*Changes To This Privacy Policy Description 1*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.changes_to_this_privacy_policy_description_1}/>
                        </div>

                        {/*Changes To This Privacy Policy Description 2*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.changes_to_this_privacy_policy_description_2}/>
                        </div>

                        {/*Contact Us Title*/}
                        <div className={a.sub_title}>
                            <PrismicRichText field={data.contact_us_title}/>
                        </div>

                        {/*Contact Us Description*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.contact_us_company_name}/>
                        </div>

                        {/*Contact Us Company Name*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.contact_us_description}/>
                        </div>

                        {/*Contact Us Company Address*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.contact_us_company_address}/>
                        </div>

                        {/*Contact Us Company Detail*/}
                        <div className={a.parm}>
                            <PrismicRichText field={data.contact_us_company_detail}/>
                        </div>
                    </Col>
                    <Col xxxl={2} xxl={2} xl={2} lg={2} md={12} sm={12} xs={12} xxs={12}></Col>
                </Row>
            </Container>
        </section>
    );
};


export default PrivacyPolicySection;
