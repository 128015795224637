import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import next3zeros_footer_tel from "../../../images/contact_us/hero/next3zeros_footer_contact_us.png";
import next3zeros_footer_email from "../../../images/contact_us/hero/next3zeros_footer_email.png";
import * as he from "./index.module.scss";
import axios from "axios";
import { PrismicRichText } from "@prismicio/react";
import get from "lodash/get";
import ArrowRight from "../../../images/common/next3zeros_button_arrow_right.svg";

const isDev = (value) => (process.env.NODE_ENV === "development" ? value : "");

const HeroSection = ({data}) => {

    const [txtFullName, setFullName] = useState("");
    const [txtUserEmail, setUEmail] = useState("");
    const [txtSubject, setSubject] = useState("");
    const [txtUserComment, setUComment] = useState("");

    const [success, setSuccess] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const [fail, setFail] = useState(false);

    const globals = get(data, "allPrismicGlobals.nodes[0].dataRaw")

    const [progress, setProgress] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFail(false);
        setProgress(true);

        // careerSubmission()
        await axios
            .post("/api/contact-submission", {
                fields: [
                    { key: "Title", value: "Send us a Message" },
                    { key: "Name", value: txtFullName },
                    { key: "Email", value: txtUserEmail },
                    { key: "Subject", value: txtSubject },
                    { key: "Comment", value: txtUserComment },
                ],
            })
            .then(() => {
                setSuccess(true);
                setProgress(false);
                setFullName("");
                setUEmail("");
                setSubject("");
                setUComment("");
            })

            .catch(() => {
                setFail(true);
                setInterval(() => {
                    setFail(false);
                }, 6000);
            });
        setProgress(false);
    };

    return (
        <header className={he.hero_section}>
            <Container className={he.com_container}>
                <Row className={he.com_row}>
                    <Col sm={12} className={he.com_cel}>
                        <div className={he.hero_constant_boxs}>
                            <div className={he.hero_section_content_left}>

                                {/*Hero Description*/}
                                <PrismicRichText field={data.hero_description}/>

                                {/*Hero Title*/}
                                <PrismicRichText field={data.hero_title}/>

                            </div>
                            <div className={he.hero_constant_box_cel}>
                                <div className={`${he.hero_section_form} ${he.hero_section_form_box}`}>

                                    {/*Hero Contact Form Title*/}
                                    <div className={he.hero_section_form_title}>
                                        <PrismicRichText field={data.hero_contact_form_title}/>
                                    </div>

                                    {/*Form Area Start*/}
                                    <Form action="" method="post" className="forms"
                                        onSubmit={(e) => {
                                            handleSubmit(e);
                                        }}
                                    >
                                        <div className={he.form_box}>
                                            <Form.Control className={he.form_box_input} id="txtFullName" type="text" required
                                                        value={txtFullName} onChange={(e) => setFullName(e.target.value)}
                                            />
                                            <label htmlFor="txtFullName"
                                                className={`${txtFullName === "" ? null : he.selected} ${he.form_box_label}`}>
                                                Full Name
                                            </label>
                                        </div>

                                        <div className={he.form_box}>
                                            <Form.Control className={he.form_box_input} id="txtUserEmail" type="email" required
                                                          value={txtUserEmail} onChange={(e) => setUEmail(e.target.value)}
                                            />
                                            <label htmlFor="txtUserEmail"
                                                   className={`${txtUserEmail === "" ? null : he.selected} ${he.form_box_label}`}>
                                                Email
                                            </label>
                                        </div>

                                        <div className={he.form_box}>
                                            <Form.Control className={he.form_box_input} id="txtSubject" type="text" required
                                                          value={txtSubject} onChange={(e) => setSubject(e.target.value)}
                                            />
                                            <label htmlFor="txtSubject"
                                                   className={`${txtSubject === "" ? null : he.selected} ${he.form_box_label}`}>
                                                Subject
                                            </label>
                                        </div>

                                        <div className={he.form_box}>
                                            <Form.Control className={he.form_box_input_text_area} id="txtUserComment" as="textarea" rows={3} required
                                                          value={txtUserComment} onChange={(e) => setUComment(e.target.value)}
                                            />
                                            <label htmlFor="txtUserComment"
                                                   className={`${txtUserComment === "" ? null : he.selected_text_area} ${he.form_box_label_text_area}`}>
                                                Your message
                                            </label>
                                        </div>

                                        <Button className={he.hero_section_form_button} type="submit"
                                                disabled={progress || success} variant="primary">
                                            Submit
                                            <img src={ArrowRight} />
                                        </Button>

                                        {success && (
                                            <div className="form-success text-success"> Thank you for your message. We will get in touch with you shortly. </div>
                                        )}
                                        {fail && (
                                            <div className="form-fail text-danger">
                                                {" "}
                                                Form submission failed. Please try again.{" "}
                                            </div>
                                        )}

                                        {/*Form Area End*/}

                                    </Form>

                                    <br/>
                                    <a href="#" className={he.hero_section_form_logo}>
                                        {/*Hero Contact Form Logo*/}
                                        <img src={data.hero_contact_form_logo} alt=""/>
                                    </a>
                                    {/*Hero Contact Form Description*/}
                                    <div className={he.hero_section_form_parm}>
                                        <PrismicRichText field={data.hero_contact_form_description}/>
                                    </div>

                                    <p className={he.hero_section_form_tel}>
                                        {/*Hero Contact Form Mobile Icon Text*/}
                                        <a href={`tel:${get(globals, "phone_number", "")}`}>
                                            {/*Hero Contact Form Mobile Icon*/}
                                            <img src={next3zeros_footer_tel}/>
                                            <label>{data.hero_tel}</label>
                                        </a>
                                    </p>
                                    <p className={he.hero_section_form_email}>
                                        {/*Hero Contact Form Email Icon Text*/}
                                        <a href={`mailto:${get(globals, "email", "")}`}>
                                            {/*Hero Contact Form Email Icon*/}
                                            <img src={next3zeros_footer_email}/>
                                            <label>{data.hero_email}</label>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </header>
    );
};

export default HeroSection;
