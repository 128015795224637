// extracted by mini-css-extract-plugin
export var com_cel = "index-module--com_cel--7e3d0";
export var com_container = "index-module--com_container--88893";
export var com_row = "index-module--com_row--df970";
export var form_box = "index-module--form_box--bb724";
export var form_box_input = "index-module--form_box_input--5e9f0";
export var form_box_input_bottom = "index-module--form_box_input_bottom--c8b85";
export var form_box_input_text_area = "index-module--form_box_input_text_area--adb2d";
export var form_box_label = "index-module--form_box_label--b93f1";
export var form_box_label_text_area = "index-module--form_box_label_text_area--c1bf3";
export var hero_constant_boxs = "index-module--hero_constant_boxs--cdae6";
export var hero_section = "index-module--hero_section--9730c";
export var hero_section_content_left = "index-module--hero_section_content_left--9772d";
export var hero_section_form = "index-module--hero_section_form--c44b6";
export var hero_section_form_box = "index-module--hero_section_form_box--cb69f";
export var hero_section_form_button = "index-module--hero_section_form_button--8fc07";
export var hero_section_form_email = "index-module--hero_section_form_email--3b45f";
export var hero_section_form_first = "index-module--hero_section_form_first--c4fe8";
export var hero_section_form_logo = "index-module--hero_section_form_logo--c3d8d";
export var hero_section_form_parm = "index-module--hero_section_form_parm--d4518";
export var hero_section_form_tel = "index-module--hero_section_form_tel--2af56";
export var hero_section_form_title = "index-module--hero_section_form_title--38341";
export var selected = "index-module--selected--0b3a2";
export var selected_text_area = "index-module--selected_text_area--07a10";