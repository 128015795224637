import React from "react";
import Seo from "../../components/seo";
import Layout from "../../components/Layout";
import PrivacyPolicySection from "../../components/PrivacyPolicy";

import { graphql } from "gatsby"
import get from "lodash/get"
import HeroSection from "../../components/ContactUs/HeroSection";

const PrivacyPolicy= ({  data, location }) => {

    const pageData = get(data, "prismicPrivacyPolicy.dataRaw", {});
    console.log(pageData);

    const hero = {
        changes_to_this_privacy_policy_description_1:get(pageData,"changes_to_this_privacy_policy_description_1",[]),
        changes_to_this_privacy_policy_description_2:get(pageData,"changes_to_this_privacy_policy_description_2",[]),
        changes_to_this_privacy_policy_title:get(pageData,"changes_to_this_privacy_policy_title",[]),
        contact_us_company_address:get(pageData,"contact_us_company_address",[]),
        contact_us_company_detail:get(pageData,"contact_us_company_detail",[]),
        contact_us_company_name:get(pageData,"contact_us_company_name",[]),
        contact_us_description:get(pageData,"contact_us_description",[]),
        contact_us_title:get(pageData,"contact_us_title",[]),
        cookies_description_1:get(pageData,"cookies_description_1",[]),
        cookies_description_2:get(pageData,"cookies_description_2",[]),
        cookies_title:get(pageData,"cookies_title",[]),
        information_collection_description:get(pageData,"information_collection_description",[]),
        information_collection_title:get(pageData,"information_collection_title",[]),
        log_data_description_1:get(pageData,"log_data_description_1",[]),
        log_data_description_2:get(pageData,"log_data_description_2",[]),
        log_data_title:get(pageData,"log_data_title",[]),
        main_description:get(pageData,"main_description",[]),
        main_title:get(pageData,"main_title",[]),
        security_description_1:get(pageData,"security_description_1",[]),
        security_description_2:get(pageData,"security_description_2",[]),
        security_title:get(pageData,"security_title",[]),
        usage_information_description_1:get(pageData,"usage_information_description_1",[]),
        usage_information_description_2:get(pageData,"usage_information_description_2",[]),
        usage_information_description_3:get(pageData,"usage_information_description_3",[]),
        usage_information_title:get(pageData,"usage_information_title",[])
    }

    return (
        <Layout location={location} title={''}>
            <PrivacyPolicySection  data={hero}/>
        </Layout>
    );
};

export const Head = ({ data }) => {
    return <Seo title={'Privacy Policy'} description={'excerpt'} />;
};
export default PrivacyPolicy;

export const query = graphql`
  query seoPersonalInsuranceQuery{
    prismicPrivacyPolicy{
      dataRaw
    }
  }
`